require("../SCSS/Stylesheet.scss");


// setup object for google maps!
import Gmap from "./Modules/GoogleMaps";
window.gmapcontroller = new Gmap();

// klaro.io cookie banner
import klaroConfig from "./Modules/KlaroConfig";
window.klaroConfig = klaroConfig;
window.klaro = require("../Packages/Klaro/dist/klaro-no-css");

// Enalbe one-click-enalbe by google placeholder:
$('.map-container .no-map').on('click', function() {
	klaro.getManager().updateConsent('gmap', true);klaro.getManager().saveAndApplyConsents();
})



// Make jQuery available
var jQuery = $ = require("jquery");

// Setup Scrollmagic Animations
import "./Modules/ScrollAnimations";

// Menu
import "./Modules/Menu";


// Setup Accordion
import "./Modules/SimpleAccordion";

// Contact Form
import "./Modules/Form";





