// Make jQuery available
var jQuery = $ = require("jquery");

$(document).ready(function() {

	$(".menu-link").click(function(event) {
		console.log('Event Triggered!');
		var target = $(this).data('target')
		if (target) {
			console.log("Target is" + target);
			var $target = $(target)
			if ($target.length) {

				var scrollpos = 0;
				var $sections = $('.section');

				var targetSection = false;
				var currentSection = 0;
				while (!targetSection) {
					if($($sections[currentSection]).find(target).length) {
						targetSection = true;
					}
					else {
						scrollpos += $($sections[currentSection]).height();
						currentSection += 1;
					}
				}
				console.log('Scrolling to: '+scrollpos);
				event.preventDefault()
				$([document.documentElement, document.body]).animate({
					scrollTop: scrollpos
				}, 1000);
			}
		}

	});

	var $button = $('.menu-toggle');
	var $menu = $('#menu');

	$button.on('click', function(e) {
		if ($menu.hasClass('expand-mobile')) {
			$(this).removeClass('is-active');
			$menu.removeClass('expand-mobile');
		}
		else {
			$(this).addClass('is-active');
			$menu.addClass('expand-mobile');

		}
	})

});
