// Make jQuery available
var jQuery = $ = require("jquery");

import { TimelineMax, TweenMax, Linear } from 'gsap';
import ScrollMagic from 'scrollmagic';
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

$(document).ready(function() {

	var form = $('#ajax-contact-form');

	// Get the messages div.
	var formMessages = $('#form-messages');

	var formFieldName = $('#ajax-contact-form-name');
	var formFieldMail = $('#ajax-contact-form-email');
	var formFieldMsg = $('#ajax-contact-form-message');
	var formFSendingIndicator = $('#form-sending-indicator');

	$(form).submit(function(event) {
		// Stop the browser from submitting the form.
		event.preventDefault();

		formMessages.text('');
		formMessages.removeClass('error');
		formMessages.removeClass('success');

		formFieldName.removeClass('error');
		formFieldMail.removeClass('error');
		formFieldMsg.removeClass('error');

		formFSendingIndicator.removeClass('d-none')

		// Security Checks
		var name = formFieldName.val()
		if(name.length < 3) {
			formMessages.addClass('error');
			formMessages.append('<p class="form-error">Bitte geben Sie einen gültigen Namen an.</p>');
			formFieldName.addClass('error');
		}


		const re_mail = /\S+@\S+\.\S+/;
		if(!re_mail.test(formFieldMail.val())) {
			formMessages.addClass('error');
			formMessages.append('<p class="form-error">Bitte geben Sie gültige E-Mail-Adresse an.</p>');
			formFieldMail.addClass('error');
		}

		var message = formFieldMsg.val()
		if(message.length < 10) {
			formMessages.addClass('error');
			formMessages.append('<p class="form-error">Bitte geben Sie längere Nachricht ein (mindestens 10 Zeichen).</p>');
			formFieldMsg.addClass('error');
		}

		if (formMessages.text() !== '') {
			return;
		}

		var formData = $(form).serialize();
		console.log(formData);

		$.ajax({
			type: 'POST',
			url: $(form).attr('action'),
			data: formData
		}).done(function(response) {
			// Make sure that the formMessages div has the 'success' class.
			$(formMessages).removeClass('error');
			$(formMessages).addClass('success');

			// Set the message text.
			$(formMessages).text(response);

			// Clear the form.
			$('#ajax-contact-form-name').val('');
			$('#ajax-contact-form-email').val('');
			$('#ajax-contact-form-message').val('');
		}).fail(function(data) {
			// Make sure that the formMessages div has the 'error' class.
			$(formMessages).removeClass('success');
			$(formMessages).addClass('error');

			// Set the message text.
			if (data.responseText !== '') {
				$(formMessages).text(data.responseText);
			} else {
				$(formMessages).innerHTML('Oops! An error occured and your message could not be sent.');
			}
		}).always(function(data) {
			formFSendingIndicator.addClass('d-none')
		});
	});

});
