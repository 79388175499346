export default class Gmap {

	constructor() {
		this.mapID = 'gmap';
		this.mapObj = window.document.getElementById(this.mapID);
		this.mapContainer = this.mapObj.parentElement;

		this.apiKey = 'AIzaSyABS0u_8ik72eltUawfN7xyhIRayCZHyYI';
		this.initFuncName = 'initMap';

		this.companyPosition =  {
			lat: 48.787258,
			lng: 8.193549
		};

		this.jsLib = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback&callback=${this.initFuncName}`;
		this.scriptId = `gmaps-${this.apiKey}`;
	}

	setupMap() {
		this.addJsTag();
		window.initMap = this.initMap();
		this.mapContainer.classList.add('mapActive');
	}

	destroyMap() {
		this.removeJsTags();
		this.removeStyleTags();
		this.mapObj.classList.remove('mapActive');
		this.mapContainer.classList.remove('mapActive');

		let keysToDelete = [
			'google',
			'map',
			'module$contents$MapsEvent_MapsEvent',
			'module$contents$mapsapi$overlay$OverlayView_OverlayView'
		]
		for (let i = 0; i < keysToDelete.length; i++) {
			if(window[keysToDelete[i]]) {
				delete window[keysToDelete[i]];
			}
		}
	}

	initMap() {

		let that = this;

		return () => {
			that.mapObj.classList.add('mapActive');
			window.map = new google.maps.Map(that.mapObj, {
				center: that.companyPosition,
				zoom: 17,
				fullscreenControl: false,
				streetViewControl: false,
				mapTypeControl: false,
				styles: [
					{
						"featureType": "poi",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					}
				]
			});

			window.onresize = function() {
				let currCenter = map.getCenter();
				google.maps.event.trigger(map, 'resize');
				map.setCenter(currCenter);
			};

			let marker = new google.maps.Marker({
				position: that.companyPosition,
				map: window.map
			});
		}

	}

	addJsTag() {
		let element = document.getElementById(this.scriptId)
		if (!element) {
			var script = document.createElement('script');
			script.src = this.jsLib;
			script.defer = true;
			script.async = true;
			script.id = this.scriptId;
			window.document.head.appendChild(script);
		}
	}


	removeJsTags() {

		console.log('Destroying Google Maps Setup');
		const regex = /\/\/maps\.googleapis\.com/gm;

		let matches = 0;
		do {
			matches = 0;
			var tags = document.getElementsByTagName('script');
			for(var i = 0; i < tags.length; i++) {
				if (regex.test(tags[i].src)) {
					matches++;
					if (tags[i]) {
						console.log(`Removing script: ${tags[i].src}`);
						tags[i].remove();
					}
				}
			}
		} while (matches);
	}

	removeStyleTags () {
		console.log('Destroying Google Maps Styles');
		const regex = /\/\/fonts\.googleapis\.com/gm;

		let matches = 0;
		do {
			matches = 0;
			var tags = document.getElementsByTagName('link');
			for(var i = 0; i < tags.length; i++) {
				if (tags[i].href && regex.test(tags[i].href)) {
					matches++;
					if (tags[i]) {
						console.log(`Removing link tag for: ${tags[i].href}`);
						tags[i].remove();
					}
				}
			}
		} while (matches);
	}


};
