
const klaroConfig = {
	elementID: 'cookie-consent',
	storageMethod: 'cookie',
	cookieName: 'cookie-consent',
	cookieExpiresAfterDays: 60,
	//cookieDomain: '.systec-solutions.com',

	// Put a link to your privacy policy here (relative or absolute).
	privacyPolicy: 'datenschutz.html',

	default: false,
	mustConsent: false,
	acceptAll: true,
	hideDeclineAll: false,


	//lang: 'en',

	// You can overwrite existing translations and add translations for your
	// app descriptions and purposes. See `src/translations/` for a full
	// list of translations that can be overwritten:
	// https://github.com/KIProtect/klaro/tree/master/src/translations

	// Example config that shows how to overwrite translations:
	// https://github.com/KIProtect/klaro/blob/master/src/configs/i18n.js
	translations: {
		de: {
			consentNotice: {
				//description: "Sehr geehrte Webseitenbesucher, wir erheben lediglich anonymisierte Daten zur Erstellung von Besucher-Statistiken um unsere Webseite weiter optimieren zu können. Diese Daten werden nicht an Dritte weitergegeben und nicht dazu genutzt, Inhalte und Anzeigen zu personalisieren."
			},
			acceptSelected: "Ausgewählten zustimmen",
			gmap: {
				description: 'Einbindung von Kartendaten des Dienstes "Google Maps".',
			},
			required:{
				title: "Notwendige Cookies",
				description: "Technisch notwendige Cookies."
			},
			purposes: {
				maps: 'Externe Kartendieste',
				settings: 'Speichern von Einstellungen für diese Website'
			},
		},
	},

	apps: [
		{
			name: 'gmap',
			default: false,
			title: 'Google Maps',
			purposes: ['maps'],

			cookies: [
			],
			callback: function(consent, app) {
				if(consent) {
					console.log('Consent for Google Maps granted.');
					window.gmapcontroller.setupMap();
				}
				else{
					console.log('Consent for Google Maps missing!')
					window.gmapcontroller.destroyMap();
				}


			},

			required: false,
			optOut: false,
			onlyOnce: false,
		},
		{
			name: 'required',
			title: '',
			purposes: ['settings'],
			required: true,
		},

	],
};

export default klaroConfig;
