// Make jQuery available
var jQuery = $ = require("jquery");

import { TimelineMax, TweenMax, Linear } from 'gsap';
import ScrollMagic from 'scrollmagic';
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

$(document).ready(function() {
	var controller = new ScrollMagic.Controller();

	$('.section').each(function (index) {
		var elementHights = {};
		elementHights[index] = $(this).height();
		$(document).on('resize', function() {
			elementHights[index] = $(this).height();
			console.log('resized for index: '+index)
		})
		var scene = new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 0,
			duration: 0,
			reverse: true
		})
			.setClassToggle(this, 'pinning')
			// .setPin(
			// 	$(this).find('.section-header')[0],
			// 	{
			// 		spacerClass: "section-pin"
			// 	}
			// )
			.addTo(controller)
	})

	$('.accordion').SimpleAccordion();


});
